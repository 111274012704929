import React from 'react';
import { ToastContainer, toast, ToastOptions, Slide,Zoom } from 'react-toastify';
import { ToastType } from '../crud-helper/ToastTypes';

type ToastProps = {
    message: string; 
    type?: ToastType; 
    autoClose?: number;
    position?: ToastOptions['position']; 
    hideProgressBar?: boolean;
    closeOnClick?: boolean; 
    pauseOnHover?: boolean; 
    draggable?: boolean; 
    preventDuplicates?: boolean; 
};

const showToast = ({
    message,
    type = ToastType.SUCCESS  , 
    autoClose = 1000,
    position = 'top-right',
    hideProgressBar = false,
    closeOnClick = true, 
    pauseOnHover = true,
    draggable = false, 
    preventDuplicates = false
}: ToastProps) => {
    
    const toastConfig: ToastOptions = {
        type,
        position,
        autoClose,
        hideProgressBar,
        closeOnClick,
        pauseOnHover,
        draggable
    };

    
    if (preventDuplicates && toast.isActive(message)) {
        return; 
    }

    toast(message, toastConfig);
};

const KTToast: React.FC = () => {
    return (
        <ToastContainer
            closeButton={false} 
            newestOnTop={false} 
            position="top-right"
            rtl={false} 
            autoClose={1000} 
            hideProgressBar={false} 
            closeOnClick={true} 
            pauseOnHover={true}
            draggable={true}
            theme="light" 
            transition={Zoom} 
            stacked
        />
    );
};

export { KTToast, showToast };