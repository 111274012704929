import { lazy, FC, Suspense } from 'react'
import { Route, Routes, Navigate } from 'react-router-dom'
import { MasterLayout } from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import { DashboardWrapper } from '../pages/dashboard/DashboardWrapper'
import { MenuTestPage } from '../pages/MenuTestPage'
import { getCSSVariableValue } from '../../_metronic/assets/ts/_utils'
import { WithChildren } from '../../_metronic/helpers'
import BuilderPageWrapper from '../pages/layout-builder/BuilderPageWrapper'
// import FreightManagement from '../modules/freight-management/FreightManagement'
import GradeGroupManagement from '../modules/grade-group-management/GradeGroupManagement'
import TransportManagement from '../modules/transport-management/TransportManagement'
import StateManagement from '../modules/state-management/StateManagement'
import AddInquiry from '../modules/inquiry-management/AddInquiry'
import ViewInquiry from '../modules/inquiry-management/ViewInquiry'
import AddSeller from '../modules/seller-management/AddSeller'
import ViewSeller from '../modules/seller-management/ViewSeller'
import DistrictListing from '../modules/district-management/DistrictListing'
import InvoiceManagement from '../modules/invoice-management/InvoiceManagement'
import AddPurchaseOrder from '../modules/purchase-order/AddPurchaseOrder'
import ViewPurchaseOrder from '../modules/purchase-order/ViewPurchaseOrder'
import AddQuotation from '../modules/quotation-management/AddQuotation'
import ViewQuotation from '../modules/quotation-management/ViewQuotation'
import ViewBuyer from '../modules/buyer-management/ViewBuyers'
import AddBuyer from '../modules/buyer-management/AddBuyer'
import EditBuyer from '../modules/buyer-management/EditBuyer'
import Report from '../modules/report/Report'
import EditSeller from '../modules/seller-management/EditSeller'
import AddProduct from '../modules/product-management/AddProduct'
import ViewProduct from '../modules/product-management/ViewProduct'
import EditProduct from '../modules/product-management/EditProduct'
import { Setting } from '../modules/settings/setting'
import EditInquiry from '../modules/inquiry-management/EditInquiry'
import EditQuotation from '../modules/quotation-management/EditQuotation'
// import TransportsPage from '../modules/masters/transports/TransportsPage'
import Location from '../modules/masters/locations/Location'
import EditPurchaseOrder from '../modules/purchase-order/EditPurchaseOrder'
import NewsContentManagement from '../modules/news-content/NewsContentManagement'
import AddArticleForm from '../modules/news-content/news-articles/AddArticle'
import EditArticle from '../modules/news-content/news-articles/EditArticle'
import CategoriesPage from '../modules/news-content/news-category/CategoriesPage'
import AddCategory from '../modules/news-content/news-category/AddCategory'
import { AdminRoute } from './AdminRoute'
import { LeaderRoute } from './LeaderRoute'
import { MarketingRoute } from './MarketingRoute'
import OfferWithPrice from '../modules/offer-with-price/OfferWithPrice'
import Disable2faPage from '../modules/auth/components/Disable2FaPage'
import SellerOffersPage from '../modules/seller-offer-management/SellerOffersPage'
import { LogisticRoute } from './LogisticRoute'
import adminRoles from '../utils/adminRoles'
import logisticRoles from '../utils/logisticRoles'
import userRoles from '../utils/userRoles'
import leaderRoles from '../utils/leaderRoles'
import marketingTeamRoles from '../utils/marketingTeamRoles'
// import CategoriesPage from '../modules/news-content/news-category/CategoriesPage'

const PrivateRoutes = () => {
  const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))
  const WizardsPage = lazy(() => import('../modules/wizards/WizardsPage'))
  const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))
  const WidgetsPage = lazy(() => import('../modules/widgets/WidgetsPage'))
  const ChatPage = lazy(() => import('../modules/apps/chat/ChatPage'))
  const SufalamsPage = lazy(() => import('../modules/sufalam-management/SufalamsPage'))
  const BuyersPage = lazy(() => import('../modules/buyer-management/BuyersPage'))
  const SellersPage = lazy(() => import('../modules/seller-management/SellersPage'))
  const ProductsPage = lazy(() => import('../modules/product-management/ProductsPage'))
  const UsersPage = lazy(() => import('../modules/masters/users/UsersPage'))
  const OffersPage = lazy(() => import('../modules/offer-management/OffersPage'))
  const InquiriesPage = lazy(() => import('../modules/inquiry-management/InquiriesPage'))
  const QuotationsPage = lazy(() => import('../modules/quotation-management/QuotationsPage'))
  const TransportsPage = lazy(() => import('../modules/masters/transports/TransportsPage'))
  const PurchasesPage = lazy(() => import('../modules/purchase-order/PurchasesPage'))
  const GradesPage = lazy(() => import('../modules/grade-group-management/GradesPage'))
  const FreightsPage = lazy(() => import('../modules/freight-management/FreightsPage'))
  const CopyFreightsPage = lazy(() => import('../modules/copy-freight-management/CopyFreightsPage'))
  const BuyerSellersPage = lazy(
    () => import('../modules/report/buyerseller-report/BuyersellersPage')
  )
  const OrdersPage = lazy(() => import('../modules/report/order-report/OrdersPage'))
  const InquirysPage = lazy(() => import('../modules/report/inquiry-invoice-analysis/InquirysPage'))
  const InvoicesPage = lazy(() => import('../modules/report/invoice-report/InvoicesPage'))
  const SelleroffersPage = lazy(
    () => import('../modules/report/seller-offer-report/SelleroffersPage')
  )
  const GradegroupsPage = lazy(() => import('../modules/report/grade-group-report/GradegroupsPage'))
  const ProductGroupsPage = lazy(
    () => import('../modules/product-group-management/ProductsGroupPage')
  )
  const ExpireOffersPage = lazy(
    () => import('../modules/report/near-expire-offer/ExpireOffersPage')
  )
  const ActiveinquiriesPage = lazy(
    () => import('../modules/report/active-inquiry-report/ActiveinquiriesPage')
  )
  const CompleteinquiriesPage = lazy(
    () => import('../modules/report/complete-inquiry-report/CompleteinquiriesPage')
  )
  const UserinquiriesPage = lazy(
    () => import('../modules/report/userwise-inquiry-report/UserinquiriesPage')
  )
  const LogisticPage = lazy(
    () => import('../modules/report/logistic-report/LogisticPage')
  )
  const AtriclesPage = lazy(() => import('../modules/news-content/news-articles/ArticlesPage'))
  const CatesPage = lazy(() => import('../modules/news-content/news-category/CatesPage'))

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path='auth/*' element={<Navigate to='/offer-management/offers' />} />
        <Route path='webpage-seller/*' element={
          // <SuspensedView>
            <SellerOffersPage />
          // </SuspensedView>
          // <h1>Create</h1>
        } />
        {/* Pages */}
        <Route path="" element={<AdminRoute />}>
          <Route path='disable/login/security' element={<Disable2faPage />} />
          <Route path='dashboard' element={<DashboardWrapper />} />
        </Route>
        <Route path='viewbuyer/:id' element={<ViewBuyer />} />
        {/* <Route path="" element={<AdminRoute />}> */}
        {
          (adminRoles() || logisticRoles()) ?
          <>
            <Route path='addbuyer' element={<AddBuyer />} />
            <Route path='editbuyer/:id' element={<EditBuyer />} />
            <Route path='addseller' element={<AddSeller />} />
            <Route path='editseller/:id' element={<EditSeller />} />
          </>
          : <></>
        }
        {/* </Route> */}
        {
          (adminRoles() || leaderRoles() || logisticRoles()) ?
          <>
            <Route path='addinquiry' element={<AddInquiry />} />
            <Route path='editinquiry/:id' element={<EditInquiry />} />
            <Route path='viewinquiry' element={<ViewInquiry />} />
            <Route path='viewinquiry/:id' element={<ViewInquiry />} />
            <Route path='viewseller/:id' element={<ViewSeller />} />
            <Route path='addpurchase' element={<AddPurchaseOrder />} />
            <Route path='viewpurchase/:id' element={<ViewPurchaseOrder />} />
            <Route path='editpurchase/:id' element={<EditPurchaseOrder />} />
            <Route path='addquotation' element={<AddQuotation />} />
            <Route path='viewquotation/:id' element={<ViewQuotation />} />
            <Route path='editquotation/:id' element={<EditQuotation />} />
            
          </>
          : <></>
        }
        <Route path="" element={<LeaderRoute />}>

          <Route path='district-management' element={<DistrictListing />} />
          <Route path='addarticle' element={<AddArticleForm />} />
          <Route path='editarticle/:id' element={<EditArticle />} />
          <Route path='addcategory' element={<AddCategory />} />
          <Route path='report' element={<Report />} />
          <Route path='addproduct' element={<AddProduct />} />
          <Route path='viewproduct/:id' element={<ViewProduct />} />
          <Route path='editproduct/:id' element={<EditProduct />} />
          <Route path='builder' element={<BuilderPageWrapper />} />
          <Route path='menu-test' element={<MenuTestPage />} />
          <Route path='add-grade-group' element={<GradeGroupManagement />} />
          {/* <Route path='freight-management' element={<FreightManagement />} /> */}
          <Route path='transport-management' element={<TransportManagement />} />
          <Route path='state-management' element={<StateManagement />} />
          <Route path='invoice-management' element={<InvoiceManagement />} />
          <Route path='news-content' element={<NewsContentManagement />} />
        </Route>

        <Route path="" element={<AdminRoute />}>
          <Route path='setting' element={<Setting />} />
          <Route path='masters/locations' element={<Location />} />
        </Route>

        {/* Lazy Modules */}
        <Route
          path='crafted/pages/profile/*'
          element={
            <SuspensedView>
              <ProfilePage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/pages/wizards/*'
          element={
            <SuspensedView>
              <WizardsPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/widgets/*'
          element={
            <SuspensedView>
              <WidgetsPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/account/*'
          element={
            <SuspensedView>
              <AccountPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/chat/*'
          element={
            <SuspensedView>
              <ChatPage />
            </SuspensedView>
          }
        />
        <Route path="" element={<AdminRoute />}>
          <Route
            path='masters/users/*'
            element={
              <SuspensedView>
                <UsersPage />
              </SuspensedView>
            }
          />
        </Route>
        {(adminRoles() || logisticRoles()) ? 
        <Route
          path='masters/transports/*'
          element={
            <SuspensedView>
              <TransportsPage />
            </SuspensedView>
          }
        /> : "" }

        <Route
          path='sufalam-management/*'
          element={
            <SuspensedView>
              <SufalamsPage />
            </SuspensedView>
          }
        />
        {(userRoles() == 1 || userRoles() == 2 || userRoles() == 4) ? 
        <>
          <Route
            path='inquiry-management/*'
            element={
              <SuspensedView>
                <InquiriesPage />
              </SuspensedView>
            }
          />
          <Route
            path='buyer-management/*'
            element={
              <SuspensedView>
                <BuyersPage />
              </SuspensedView>
            }
          />

          <Route
            path='seller-management/*'
            element={
              <SuspensedView>
                <SellersPage />
              </SuspensedView>
            }
          />

          <Route
            path='quotation-management/*'
            element={
              <SuspensedView>
                <QuotationsPage />
              </SuspensedView>
            }
          />

          <Route
            path='purchase-order/*'
            element={
              <SuspensedView>
                <PurchasesPage />
              </SuspensedView>
            }
          />
        </>
          : <></>
        }
        {/* 
        <Route path="" element={<LeaderRoute />}>
        </Route> */}
        <Route path="" element={<AdminRoute />}>
          <Route
            path='product-management/*'
            element={
              <SuspensedView>
                <ProductsPage />
              </SuspensedView>
            }
          />
        </Route>

        {/* <Route path="" element={<LogisticRoute />}> */}

        <Route
          path='offer-management/*'
          element={
            <SuspensedView>
              <OffersPage />
            </SuspensedView>
          }
        />

        {/* </Route> */}
        {(adminRoles() || logisticRoles()) ?
          <Route
            path='offer-with-price'
            element={
              // <SuspensedView>
              <OfferWithPrice />
              // </SuspensedView>
            }
          /> : ""}

        <Route path="" element={<AdminRoute />}>
          <Route
            path='grade-group-management/*'
            element={
              <SuspensedView>
                <GradesPage />
              </SuspensedView>
            }
          />

          <Route
            path='product-group-management/*'
            element={
              <SuspensedView>
                <ProductGroupsPage />
              </SuspensedView>
            }
          />

        </Route>

        {(adminRoles()) ?
        <> 
          <Route
            path='buyerseller-report/*'
            element={
              <SuspensedView>
                <BuyerSellersPage />
              </SuspensedView>
            }
          /> 
          <Route
            path='order-report/*'
            element={
              <SuspensedView>
                <OrdersPage />
              </SuspensedView>
            }
          />
          <Route
            path='inquiry-report/*'
            element={
              <SuspensedView>
                <InquirysPage />
              </SuspensedView>
            }
          />
        </> : "" }

        {(adminRoles() || logisticRoles()) ? <>
          {/* <Route
            path='freight-management/*'
            element={
              <SuspensedView>
                <FreightsPage />
              </SuspensedView>
            }
          /> */}

          <Route
            path='copy-freight-management/*'
            element={
              <SuspensedView>
                <CopyFreightsPage />
              </SuspensedView>
            }
          />
        </>
        :""}

        <Route
          path='invoice-report/*'
          element={
            <SuspensedView>
              <InvoicesPage />
            </SuspensedView>
          }
        />

        <Route
          path='seller-offer-report/*'
          element={
            <SuspensedView>
              <SelleroffersPage />
            </SuspensedView>
          }
        />
        <Route path="" element={<AdminRoute />}>
          <Route
            path='grade-group-report/*'
            element={
              <SuspensedView>
                <GradegroupsPage />
              </SuspensedView>
            }
          />

          <Route
            path='/news-articles/*'
            element={
              <SuspensedView>
                <AtriclesPage />
              </SuspensedView>
            }
          />

          <Route
            path='/news-category/*'
            element={
              <SuspensedView>
                <CatesPage />
              </SuspensedView>
            }
          />

          <Route
            path='near-expire-offer/*'
            element={
              <SuspensedView>
                <ExpireOffersPage />
              </SuspensedView>
            }
          />


          <Route
            path='active-inquiry-report/*'
            element={
              <SuspensedView>
                <ActiveinquiriesPage />
              </SuspensedView>
            }
          />

          <Route
            path='complete-inquiry-report/*'
            element={
              <SuspensedView>
                <CompleteinquiriesPage />
              </SuspensedView>
            }
          />

          <Route
            path='userwise-inquiry-report/*'
            element={
              <SuspensedView>
                <UserinquiriesPage />
              </SuspensedView>
            }
          />
          
        </Route>

        <Route
          path='operation-report/*'
          element={
            <SuspensedView>
              <LogisticPage />
            </SuspensedView>
          }
        />

        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({ children }) => {
  const baseColor = getCSSVariableValue('--kt-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export { PrivateRoutes }
