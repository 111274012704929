import clsx from 'clsx'
import React, {useEffect, useState} from 'react'
import {Col, Row} from 'react-bootstrap'
import {Link, useNavigate, useParams} from 'react-router-dom'
import {defaultAlerts, defaultLogs, KTSVG, toAbsoluteUrl} from '../../../_metronic/helpers'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'
import {
  getIGST,
  getInquiryById,
  getInquiryByIdNew,
  getQuotationHistoryByIdNew,
} from './Inquiry-list/core/_requests'
import {useSearchParams} from 'react-router-dom'
import InfiniteScroll from 'react-infinite-scroll-component'
import {checkDisplayValue, getFormatPrice} from '../../utils/utils'
import moment from 'moment'

const wizardsBreadCrumbs: Array<PageLink> = [
  {
    title: 'Inquiry Management',
    path: '/crafted/pages/wizards/horizontal',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const ViewInquiry = () => {
  const navigate = useNavigate()
  const params = useParams()
  const id: string = params.id ? params.id : ''
  const [objData, setObjData] = useState<any>({})
  const [page, setPage] = useState<any>(1)
  const [perPage, setPerPage] = useState<any>(10)
  const [matches, setMatches] = React.useState([])
  const [totalRecored, setTotalRecored] = React.useState(0)
  const [initialLoading, setInitialLoading] = React.useState(true)
  const [valueIGST, setValueIGST] = useState(0)

  const navigateInuiryListing = () => {
    // 👇️ navigate to /
    navigate('/inquiry-management/inquiries')
  }
  const navigateViewInquiry = () => {
    // 👇️ navigate to /
    navigate('/addinquiry')
  }

  useEffect(() => {
    if (id) {
      getByID(id)
    }
  }, [id, page])

  useEffect(() => {
    getGst()
  }, [])

  const getGst = async () => {
    // IGST
    let IGST: any = await getIGST()
    let tmpIGST = IGST.responseData.value
    setValueIGST(tmpIGST)
  }

  const getByID = async (id: string) => {
    let qury = `?perPage=${perPage}&page=${page}&inquiry_id=${id}`
    let data: any = await getInquiryByIdNew(id)
    let dataInquiry: any = await getQuotationHistoryByIdNew(qury)
    console.log(data, 'data--')
    console.log(dataInquiry.responseData, 'dataInquiry---')
    setMatches(dataInquiry.responseData.list)
    setInitialLoading(false)
    // setTotalRecored(dataInquiry.responseData.)

    if (data.responseData) {
      setObjData(data.responseData)
    }
  }
  console.log(objData, 'data')

  const fetchMoreMatches = () => {
    if (matches.length >= totalRecored) {
      // setHasMore(false)
      return
    }

    // setTimeout(() => {
    // setItems(items.concat(Array.from({ length: 20 })))
    setPage(page + 1)
    // onMore()
    // }, 500);
  }

  const getTotal = (objProduct: any) => {
    let tmp: any =
      parseFloat(objProduct.rate) && parseFloat(objProduct.product_quantity)
        ? parseFloat(objProduct.rate) * parseFloat(objProduct.product_quantity) * 1000
        : 0
    let tax = (tmp / 100) * valueIGST
    let finalAmt = tmp
    return finalAmt.toFixed(2)
  }

  return (
    <>
      <PageTitle>Inquiry Detail</PageTitle>
      <Col className='d-flex position-absolute me-5 end-0 tp-btn-mn'>
        <button
          type='button'
          onClick={navigateInuiryListing}
          value=''
          className='btn btn-sm fs-5 me-5 btn btn-primary'
        >
          Back
        </button>
      </Col>

      {/* <div className='app-content flex-column-fluid'>
        <div className='app-container container-xxl'>
          <Col className='card h-100'>
            <Col className='card-body'> */}
      <Col className='card card-body backgroung-transparent p-12 mt-10 p-12'>
        <Col xl={12} className='d-flex justify-content-center'>
          <div className='menu menu-column' data-kt-menu='true'>
            <div
              className='d-flex flex-column bgi-no-repeat rounded-top'
              style={{backgroundImage: `url('{toAbsoluteUrl('/media/misc/menu-header-bg.jpg')}')`}}
            >
              <ul className='nav nav-line-tabs nav-line-tabs-2x nav-stretch fw-bold  px-0'>
                <li className='nav-item'>
                  <a
                    className='nav-link text-dark opacity-75 fs-4 px-5 opacity-state-100 pb-4  active'
                    data-bs-toggle='tab'
                    href='#kt_topbar_notifications_1'
                  >
                    Inquiry Details
                  </a>
                </li>

                <li className='nav-item'>
                  <a
                    className='nav-link text-dark opacity-75 fs-4 opacity-state-100 pb-4'
                    data-bs-toggle='tab'
                    href='#kt_topbar_notifications_2'
                  >
                    Inquiry Log
                  </a>
                </li>

                {/* <li className='nav-item'>
              <a
                className='nav-link text-white opacity-75 opacity-state-100 pb-4'
                data-bs-toggle='tab'
                href='#kt_topbar_notifications_3'
              >
                Logs
              </a>
            </li> */}
              </ul>
            </div>

            <div className='tab-content'>
              <div
                className='tab-pane show active fade'
                id='kt_topbar_notifications_1'
                role='tabpanel'
              >
                <Col className=' flex-column flex-lg-row-auto py-8'>
                  <Row className='mb-2'>
                    <Col xl={5} className='align-items-center pb-5'>
                      <Col className='fs-5 fw-bolder text-gray-600 text-hover-primary'>
                        Inquiry ID
                      </Col>
                      <Col className='fw-bolder text-dark fs-5 mt-1 text-hover-primary '>
                        {objData.inquiry_number}
                        {/* {objData.status ? (
                          <span
                            className='badge fs-6 badge-light-success ms-10'
                            data-kt-menu-dismiss='true'
                          >
                            Approved
                          </span>
                        ) : (
                          <span
                            className='badge fs-6 badge-light-warning ms-10'
                            data-kt-menu-dismiss='true'
                          >
                            Pending
                          </span>
                        )} */}
                      </Col>
                    </Col>
                    <Col xl={4} className='text-hover-primary align-items-center pt-0 pb-3'>
                      <Col className='fs-5 fw-bolder text-gray-600 text-hover-primary'>
                        Assigned
                      </Col>
                      <Col className='fw-bolder text-dark fs-5 mt-1 text-hover-primary'>
                        <span className='badge fs-6 badge-light-success'>
                          {objData?.customerInfo?.name ?? '-'}
                        </span>
                      </Col>
                    </Col>
                    <Col xl={3} className='text-hover-primary align-items-center pt-0 pb-3'>
                      <Col className='fs-5 fw-bolder text-gray-600 text-hover-primary'>Status</Col>
                      <Col className='fw-bolder text-dark fs-5 mt-1 text-hover-primary'>
                        <span className='badge fs-6 badge-light-success'>
                          {objData?.inquiryStatusInfo?.name ?? '-'}
                        </span>
                      </Col>
                    </Col>
                  </Row>
                  {/* <Row className='mb-8'>
                    <Col xl={4} className='align-items-center pb-5'>
                      <Col className='fs-5 fw-bolder text-gray-600 text-hover-primary'>
                        Quotation No.
                      </Col>
                      <Col className='fw-bolder text-dark fs-5 mt-1 text-hover-primary '>
                        485676
                      </Col>
                    </Col>
                  </Row> */}

                  <h2 className='mb-7'> Buyer's Details </h2>

                  <Row className='mb-3'>
                    <Col className='align-items-center '>
                      <Col className='fs-5 fw-bolder text-gray-600 text-hover-primary'>
                        Firm Name
                      </Col>
                      <Col className='fw-bolder text-dark fs-5 mt-1 text-hover-primary'>
                        {objData.buyerSellerInfo && objData.buyerSellerInfo.firm_name}
                      </Col>
                    </Col>
                  </Row>
                  <Row className='mb-10'>
                    <Col xl={2} md={6} className='align-items-center pt-3 pb-5'>
                      <Col className='fs-5 fw-bolder text-gray-600 text-hover-primary'>
                        Buyer Name
                      </Col>
                      <Col className='fw-bolder text-dark fs-5 mt-1 text-hover-primary'>
                      {checkDisplayValue(objData?.firmBuyerInfo?.name)}
                      </Col>
                    </Col>
                    <Col xl={2} md={6} className='align-items-center pt-3 pb-5'>
                      <Col className='fs-5 fw-bolder text-gray-600 text-hover-primary'>
                        Whatsapp Number
                      </Col>
                      <Col className='fw-bolder text-dark fs-5 mt-1 text-hover-primary'>
                        {checkDisplayValue(objData?.firmBuyerInfo?.whatsapp_number)}
                      </Col>
                    </Col>
                    <Col xl={2} md={6} className='align-items-center pt-3 pb-5'>
                      <Col className='fs-5 fw-bolder text-gray-600 text-hover-primary'>
                        Shipping City
                      </Col>
                      <Col className='fw-bolder text-dark fs-5 mt-1 text-hover-primary'>{checkDisplayValue(objData?.shippingAddressLocationInfo?.city )}</Col>
                    </Col>
                    <Col xl={2} md={6} className='align-items-center pt-3 pb-5'>
                      <Col className='fs-5 fw-bolder text-gray-600 text-hover-primary'>
                        Billing City
                      </Col>
                      <Col className='fw-bolder text-dark fs-5 mt-1 text-hover-primary'>{checkDisplayValue(objData?.billingAddressLocationInfo?.city)}</Col>
                    </Col>
                    <Col xl={2} md={6} className='align-items-center pt-3 pb-5'>
                      <Col className='fs-5 fw-bolder text-gray-600 text-hover-primary'>
                        Expected Date
                      </Col>
                      <Col className='fw-bolder text-dark fs-5 mt-1 text-hover-primary'>{objData?.expected_arrival_date && objData?.expected_arrival_date != "" ? moment(new Date(objData?.expected_arrival_date)).format('DD-MM-YYYY') : "-"}</Col>
                    </Col>
                  </Row>

                  <h4 className='mb-7'> Product Details </h4>
                  {objData.inquiryInfo &&
                    objData.inquiryInfo.map((obj: any) => {
                      return (
                        <Row className='mb-7'>
                          <Col xl={2} md={6}>
                            <label className='fs-5 fw-bolder text-gray-600 text-hover-primary'>
                              Offer Id
                            </label>
                            <div className='fw-bolder text-dark fs-5 mt-1 text-hover-primary'>
                              {checkDisplayValue(obj?.offer_number)}
                            </div>
                          </Col>
                          <Col xl={2} md={6}>
                            <label className='fs-5 fw-bolder text-gray-600 text-hover-primary'>
                              Product Name
                            </label>
                            <div className='fw-bolder text-dark fs-5 mt-1 text-hover-primary'>
                              {obj.productInfo.product_variation}
                            </div>
                          </Col>
                          <Col xl={2} md={6}>
                            <label className='fs-5 fw-bolder text-gray-600 text-hover-primary'>
                              Grade Group
                            </label>
                            <div className='fw-bolder text-dark fs-5 mt-1 text-hover-primary'>
                              {obj.productInfo?.gradeGroupInfo?.name}
                            </div>
                          </Col>
                          <Col xl={2} md={6}>
                            <label className='fs-5 fw-bolder text-gray-600 text-hover-primary'>
                              Inquiry Quantity(MT)
                            </label>
                            <div className='fw-bolder text-dark fs-5 mt-1 text-hover-primary'>
                              {obj.product_quantity}
                            </div>
                          </Col>
                          <Col xl={2} md={6}>
                            <label className='fs-5 fw-bolder text-gray-600 text-hover-primary'>
                              Inquiry Rate/Kg
                            </label>
                            <div className='fw-bolder text-dark fs-5 mt-1 text-hover-primary'>
                              {obj.rate}
                            </div>
                          </Col>
                          <Col xl={2} md={6}>
                            <label className='fs-5 fw-bolder text-gray-600 text-hover-primary'>
                              Total Amount
                            </label>
                            <div className='fw-bolder text-dark fs-5 mt-1 text-hover-primary'>
                              {getFormatPrice(getTotal(obj))}
                              {/* {parseFloat(obj.rate) * parseFloat(obj.product_quantity) * 1000 *18} */}
                            </div>
                          </Col>
                        </Row>
                      )
                    })}
                  {/* <Row>
                    <Col xl={3} md={6}>
                      <label className='fs-5 fw-bolder text-gray-600 text-hover-primary'>
                        Product Name
                      </label>
                      <div className='fw-bolder text-dark fs-5 mt-1 text-hover-primary'>
                        ABS MOULDING LG HI121
                      </div>
                    </Col>
                    <Col xl={3} md={6}>
                      <label className='fs-5 fw-bolder text-gray-600 text-hover-primary'>
                        Grade Group
                      </label>
                      <div className='fw-bolder text-dark fs-5 mt-1 text-hover-primary'>ABS</div>
                    </Col>
                    <Col xl={3} md={6}>
                      <label className='fs-5 fw-bolder text-gray-600 text-hover-primary'>
                        Inquiry Quantity(MT)
                      </label>
                      <div className='fw-bolder text-dark fs-5 mt-1 text-hover-primary'>125</div>
                    </Col>
                    <Col xl={3} md={6}>
                      <label className='fs-5 fw-bolder text-gray-600 text-hover-primary'>
                        Inquiry Rate/Kg
                      </label>
                      <div className='fw-bolder text-dark fs-5 mt-1 text-hover-primary'>250</div>
                    </Col>
                  </Row> */}
                </Col>

                {objData.transportInfo ? (
                  <Col xl={12}>
                    <h4 className='mb-7'> Transport Details </h4>
                    <Row className='mb-7'>
                      <Col xl={2} md={4} className='mb-5'>
                        <label className='fs-5 fw-bolder text-gray-600 text-hover-primary'>
                          Firm Name
                        </label>
                        <div className='fw-bolder text-dark fs-5 mt-1 text-hover-primary'>
                          {checkDisplayValue(objData?.transportInfo?.firm_name)}
                        </div>
                      </Col>
                      <Col xl={2} md={4} className='mb-5'>
                        <label className='fs-5 fw-bolder text-gray-600 text-hover-primary'>
                          Driver Name
                        </label>
                        <div className='fw-bolder text-dark fs-5 mt-1 text-hover-primary'>
                          {checkDisplayValue(objData?.driver_name)}
                        </div>
                      </Col>
                      <Col xl={2} md={4} className='mb-5'>
                        <label className='fs-5 fw-bolder text-gray-600 text-hover-primary'>
                          Mobile Number
                        </label>
                        <div className='fw-bolder text-dark fs-5 mt-1 text-hover-primary'>
                          {checkDisplayValue(objData?.mobile_number)}
                        </div>
                      </Col>
                      <Col xl={2} md={4} className='mb-5'>
                        <label className='fs-5 fw-bolder text-gray-600 text-hover-primary'>
                          Vehicle Number
                        </label>
                        <div className='fw-bolder text-dark fs-5 mt-1 text-hover-primary'>
                          {checkDisplayValue(objData?.vehicle_number)}
                        </div>
                      </Col>
                      <Col xl={2} md={4} className='mb-5'>
                        <label className='fs-5 fw-bolder text-gray-600 text-hover-primary'>
                          Loading Charges
                        </label>
                        <div className='fw-bolder text-dark fs-5 mt-1 text-hover-primary'>
                          {checkDisplayValue(objData?.logisticInfo?.loading_charges)}
                        </div>
                      </Col>
                      <Col xl={2} md={4} className='mb-5'>
                        <label className='fs-5 fw-bolder text-gray-600 text-hover-primary'>
                          Transportation Charges
                        </label>
                        <div className='fw-bolder text-dark fs-5 mt-1 text-hover-primary'>
                          {checkDisplayValue(objData?.logisticInfo?.transportation_charges)}
                        </div>
                      </Col>
                      <Col xl={2} md={4} className='mb-5'>
                        <label className='fs-5 fw-bolder text-gray-600 text-hover-primary'>
                          LR Number
                        </label>
                        <div className='fw-bolder text-dark fs-5 mt-1 text-hover-primary'>
                          {checkDisplayValue(objData?.logisticInfo?.lr_number)}
                        </div>
                      </Col>
                    </Row>
                  </Col>
                ) : (
                  ''
                )}

                {objData?.eway_bill_file || objData?.invoiceFiles?.length > 0  ? (
                  <Col xl={6}>
                    <h4 className='mb-7'> Inquiry Document</h4>
                    <Row className='mb-7'>
                      <Col xl={6} md={6}>
                        <label className='fs-5 fw-bolder text-gray-600 text-hover-primary'>
                          Invoice Document
                        </label>               
                    {objData?.invoiceFiles?.length > 0 ? (
                      objData?.invoiceFiles?.map((file: any, index: number) => (
                        <div className='fw-bolder text-dark fs-5 mt-1 text-hover-primary'>
                          <a
                            href={`${process.env.REACT_APP_API_URL_NEW}/downloads/download?folder_name=invoice&image=${file?.file_name}`}
                            download
                            target='_blank'
                          >
                            {file?.file_name}
                          </a>
                        </div>))
                        ) : (
                          <span>No File Found</span>
                        )}
                      </Col>

                      <Col xl={6} md={6}>
                        <label className='fs-5 fw-bolder text-gray-600 text-hover-primary'>
                          E-Way Bill
                        </label>
                        <div className='fw-bolder text-dark fs-5 mt-1 text-hover-primary'>
                          <a
                            href={`${process.env.REACT_APP_API_URL_NEW}/downloads/download?folder_name=invoice&image=${objData?.eway_bill_file}`}
                            download
                            target='_blank'
                          >
                            {objData?.eway_bill_file}
                          </a>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                ) : (
                  ''
                )}

                {/* <div className='py-3 text-center border-top'>
              <Link
                to='/crafted/pages/profile'
                className='btn btn-color-gray-600 btn-active-color-primary'
              >
                View All{' '}
                <KTSVG path='/media/icons/duotune/arrows/arr064.svg' className='svg-icon-5' />
              </Link>
            </div> */}
              </div>

              <div className='tab-pane fade' id='kt_topbar_notifications_2' role='tabpanel'>
                <Col xl={6} className=' flex-column flex-lg-row-auto py-8  '>
                  <Row className='d-flex justify-content-between'>
                    <Col>
                      <InfiniteScroll
                        dataLength={matches.length}
                        next={fetchMoreMatches}
                        hasMore={true}
                        style={{overflow: 'hidden', paddingTop: 3}}
                        loader={
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'center',
                              width: '95%',
                              margin: '10px',
                            }}
                          ></div>
                        }
                        endMessage={<p style={{paddingBottom: '60px'}}></p>}
                      >
                        {matches.length
                          ? matches.map((obj: any) => {
                              return (
                                <div className='timeline-item d-flex'>
                                  <div className='timeline-icon symbol symbol-circle symbol-40px me-4'>
                                    <div className='symbol-label bg-light'>
                                      <KTSVG
                                        path='/media/icons/duotune/communication/com003.svg'
                                        className='svg-icon-2 svg-icon-gray-500'
                                      />
                                    </div>
                                  </div>

                                  <div className='timeline-content mb-10 mt-n1'>
                                    <div className='pe-3 mb-5'>
                                      <div className='fs-5 fw-bolder  text-gray-600 text-hover-primary pt-2 mb-2'>
                                        {/* {obj.created_by_user_name}{' '} */}
                                        <span className='text-dark fw-bold fs-5 mt-1 text-hover-primary'>
                                          {' '}
                                          {obj.action}{' '}
                                        </span>
                                      </div>
                                      <div className='d-flex align-items-center mt-1 fs-6'>
                                        <div className='text-muted me-2 fs-7'>
                                          {/* { 15<sup>th</sup> Oct. 2021, at 2:23 PM} */}
                                          {moment(obj.created_at).format('LLL')}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )
                            })
                          : ''}
                      </InfiniteScroll>

                      {/* <div className='timeline-item d-flex'>
                        <div className='timeline-icon symbol symbol-circle symbol-40px me-4'>
                          <div className='symbol-label bg-light'>
                            <KTSVG
                              path='/media/icons/duotune/communication/com003.svg'
                              className='svg-icon-2 svg-icon-gray-500'
                            />
                          </div>
                        </div>
                        <div className='timeline-content mb-10 mt-n1'>
                          <div className='pe-3 mb-5'>
                            <div className='fs-5 fw-bolder  text-gray-600 text-hover-primary pt-2 mb-2'>
                              <span className='text-dark fw-bold fs-5 mt-1 text-hover-primary'>
                                {' '}
                                Dharmendra Kushwah{' '}
                              </span>{' '}
                              has Created Inquiry
                            </div>

                            <div className='d-flex align-items-center mt-1 fs-6'>
                              <div className='text-muted me-2 fs-7'>
                                Added on 10<sup>th</sup> Oct. 2021, at 4:23 PM
                              </div>
                            </div>
                          </div>
                        </div>
                      </div> */}
                    </Col>
                  </Row>
                </Col>
                {/* <div className='py-3 text-center border-top'>
                  <Link
                    to='/crafted/pages/profile'
                    className='btn btn-color-gray-600 btn-active-color-primary'
                  >
                    View All{' '}
                    <KTSVG path='/media/icons/duotune/arrows/arr064.svg' className='svg-icon-5' />
                  </Link>
                </div> */}
              </div>
            </div>
          </div>
        </Col>
      </Col>

      {/* </Col>
          </Col>
        </div>
      </div> */}
    </>
  )
}

export default ViewInquiry
