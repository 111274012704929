import React, { useCallback, useEffect, useState } from 'react'
import {Button, Col, Form, Row, Spinner, Toast, ToastContainer} from 'react-bootstrap'
import {KTSVG} from '../../../../_metronic/helpers'
import Select from 'react-select'
import {useDropzone} from 'react-dropzone'
import CustomCkEditor from '../../ckeditor/CustomCkEditor'
import { useFormik } from 'formik'
import { createArticleApi, downloadMediaApi, editArticleApi, updateArticleApi, uploadMedia } from './articles-list/core/_requests'
import { getCate } from '../news-category/cates-list/core/_requests'
import { useParams, useNavigate } from 'react-router-dom'
import moment from 'moment'
import { useIntl } from 'react-intl'

const AddEditNewsArticle = ({isEditing}:any) => {
  const navigate = useNavigate()
  const [file, setFile] = useState<any>()
  const [fileName, setFileName] = useState<any>()
  const [isLoading,setIsLoading] = useState<any>(false)
  const [categoryList,setCategoryList] = useState<any>([])
  const {id} = useParams()
  const [loadCKEditor,setLoadCKEditor] = useState<any>(true)
  const [btnLoading,setBTNLoading] = useState<any>(false)
  const intl = useIntl()
  const onDrop = useCallback((acceptedFiles: any) => {
    acceptedFiles.forEach((file: any) => {
      const reader = new FileReader();
  
      reader.onload = (event: any) => {
        const img = new Image();
        img.src = event.target.result;
  
        img.onload = () => {
          // Validate image dimensions
          if (img.width !== 1000 || img.height !== 1000) {
            setSessionMessage({
              type: 'danger',
              message: intl.formatMessage({ id: 'PLEASE.UPLOAD.AN.IMAGE.OF.1000*1000.PIXELS' }),
              show: true,
            });
            return;
          }
  
          // Proceed to upload if dimensions are valid
          setFile(file);
          uploadArticleImageApi(file);
        };
      };
  
      reader.onerror = () => {};
  
      reader.onabort = () => {};
  
      reader.readAsDataURL(file);
    });
  }, []);

  const {getRootProps, getInputProps, open, acceptedFiles} = useDropzone({
    noClick: true,
    noKeyboard: true,
    onDrop,
    accept: {
      'image/png': ['.png','.jpg'],
    }
  })

  const [sessionMessage, setSessionMessage] = useState({
    type: '',
    message: '',
    show: false,
  })

  const validate = (values:any) => {
    const errors = {} as any;
    if (!values.title) {
      errors.title = 'This title field is required';
    }
    if (!values.category) {
      errors.category = 'This category field is required';
    }
    if (!values.content) {
      errors.content = 'This content field is required';
    }
    // if (!values.created_at) {
    //   errors.created_at = 'This date field is required';
    // }
    return errors;
  };

  const formik = useFormik({
    validate,
    validateOnChange:false,
    initialValues: {
      title: '',
      category: '',
      content: '',
      image:'',
      created_at:'',
    },
    onSubmit: (values:any) => {
      addEditArticleApi(values)
    }
  }) as any;

  const setCategoryListState = async () => {
    let response = await getCate(`perPage=1000&page=1`)
    if(response.responseStatus == 200){
      let data = response?.data || []
      let newData = data.map((x:any,i:any)=>{
        x.label = x.name
        x.value = x.id
        return x
      })
      setCategoryList(newData)
    }
  }

  const addEditArticleApi = async (data:any) => {
    setBTNLoading(true)
    try{
      let formData = {
        title: data.title,
        category_id: data.category,
        content: data.content,
        image_name: fileName ?? "",
        created_at: data?.created_at,
      }
      if(isEditing){
        var response = await updateArticleApi(id,formData)
      }
      else{
        var response = await createArticleApi(formData)
      }
      if(response.responseStatus == 200){
        setSessionMessage({type: 'success',message: response.responseMessage ,show: true})
        setTimeout(()=>{navigate('/news-articles/articles')},1500)
      }
      else{
        setSessionMessage({type: 'danger',message: response.responseMessage ,show: true})
      }
      setBTNLoading(false)
    }
    catch(err){
      setSessionMessage({type: 'danger',message: "Something Went Wrong" ,show: true})
      setBTNLoading(false)
    }
  }

  const getArticleApi = async () => {
    setIsLoading(true)
    setLoadCKEditor(true)
    try{
      var response = await editArticleApi(id)
      if(response?.responseStatus == 200){
        let data = response?.responseData ?? {}
        formik.setFieldValue("title",data.title)
        formik.setFieldValue("content",data.content)
        formik.setFieldValue("category",data.category_id)
        formik.setFieldValue("created_at",data.created_at)
        setFileName(data.image_name)
      }
      else{
        setSessionMessage({type: 'danger',message: response.responseMessage ,show: true})
      }
      setIsLoading(false)
      setTimeout(()=>{
        setLoadCKEditor(false)
      },2000)
    }
    catch(err){
      setSessionMessage({type: 'danger',message: "Something Went Wrong" ,show: true})
      setIsLoading(false)
      setLoadCKEditor(false)
    }
  }

  const uploadArticleImageApi = async (file:any) => {
    setIsLoading(true)
    try{
      let formData = new FormData()
      formData.append("news",file)
      var response = await uploadMedia(formData)
      if(response.newFilename != ""){
        setFileName(response.newFilename)
        setSessionMessage({type: 'success',message: "Image Upload Successfully" ,show: true})
      }
      else{
        setSessionMessage({type: 'danger',message: "Something Went Wrong" ,show: true})
      }
      setIsLoading(false)
    }
    catch(err){
      setSessionMessage({type: 'danger',message: "Something Went Wrong" ,show: true})
      setIsLoading(false)
    }
  }

  const files = acceptedFiles.map((file: any) => (
    <li key={file.path}>
      {file.path} - {file.size} bytes
    </li>
  ))

  useEffect(()=>{
    if(id){
      getArticleApi()
    }else{
      setLoadCKEditor(false)
    }
  },[id])

  useEffect(()=>{
    setTimeout(()=>{
      setSessionMessage({type: '',message: '',show: false})
    },5000)
  },[sessionMessage.show])

  useEffect(()=>{
    setCategoryListState()
  },[])

  const dateForPicker = (dateString: any) => {
    return moment(new Date(dateString)).format('YYYY-MM-DD')
  }

  return (
    <>
      <Col xl={6} className='ms-auto me-auto'>
        <Form className='p-5' id='kt_modal_add_user_form' onSubmit={formik.handleSubmit}>
          {isLoading ?
          <div
            style={{
              height: '550px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Spinner animation='border' />
          </div>
          : <>
            <Col className='mb-7'>
              <Col>
                <Col className='mb-8'>
                  <Form.Group>
                    <Form.Label className='required fw-bold fs-6 mb-1 form-label'>Title</Form.Label>
                    <Form.Control
                      type='text'
                      className='form-control form-control-solid mb-lg-0'
                      placeholder='Title'
                      onChange={(e:any)=>{
                        formik.setFieldValue("title",e.target.value)
                      }}
                      value={formik.values.title}
                    />
                    <span className='text-danger'>{formik.errors?.title}</span>
                  </Form.Group>
                </Col>
                <Col className='mb-8'>
                  <Form.Group>
                    <Form.Label className='fw-bold fs-6 mb-1 form-label'>Date</Form.Label>
                    <Form.Control
                      type='Date'
                      className='form-control-solid'
                      placeholder='Select Date'
                      value={formik.values?.created_at ? dateForPicker(formik.values?.created_at) : ''}
                      onChange={(e: any) => {
                        formik.setFieldValue('created_at', e.target.value)
                      }}
                    />
                    {/* <span className='text-danger'>{formik.errors?.created_at}</span> */}
                  </Form.Group>  
                </Col>  
                {fileName && 
                <Col className='mb-8'>
                  <Col className='text-start'>
                    <img src={downloadMediaApi(fileName)} width={150} height={150}></img>
                  </Col>
                </Col>}
                <Col className='mb-8'>
                  <Col className='text-start'>
                    <Form.Label className='form-label text'>Here Image Selection</Form.Label>
                    <div onClick={open}>
                      <div {...getRootProps({className: 'dropzone py-12'})}>
                        <input {...getInputProps()} />
                        <div className='dz-message needsclick'>
                          <span className='svg-icon svg-icon-3hx svg-icon-primary'>
                            <KTSVG
                              path='/media/icons/duotune/files/fil009.svg'
                              className='svg-icon-muted svg-icon-2hx'
                            />
                          </span>
                          <div className='ms-4'>
                            <h3 className='fs-5 fw-bold text-gray-900 pt-5'>
                              Drag/Drop files here or click here
                            </h3>
                            {/* <span className='fw-semibold fs-4 text-muted'>Upload files</span> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Col>
                <Col className='mb-7'>
                  <Form.Group>
                    <Form.Label className='required fw-bold fs-6 mb-1 form-label'>
                      Select Category
                    </Form.Label>
                    <Select
                      options={categoryList}
                      className='custom_select mb-3'
                      classNamePrefix='Select'
                      placeholder='Select Category'
                      onChange={(e:any)=>{
                        formik.setFieldValue("category",e.value)
                      }}
                      value={categoryList.find((x:any)=>x.value==formik.values.category)}
                    />
                    <span className='text-danger'>{formik.errors?.category}</span>
                  </Form.Group>
                </Col>
              </Col>
              <Col>
                <Form.Group>
                  <Form.Label className='required fw-bold fs-6 mb-1 form-label'>
                    News Content
                  </Form.Label>
                  {
                    loadCKEditor ? 
                    <div className='text-center px-5' style={{fontSize:'17px',fontWeight:'450'}}>Loading...</div> :
                    <CustomCkEditor
                      key={'NewArticle'} 
                      changeData={(value:any)=>{
                        formik.setFieldValue("content",value)
                      }}
                      data={formik.values.content}
                    />
                  }
                  {/* {JSON.stringify(formik.values)} */}
                  <span className='text-danger'>{formik?.errors?.content as any}</span>
                  {/* <CKEditor initData="" /> */}
                  {/* <Form.Control type='text' className='form-control form-control-solid mb-lg-0' rows={5} as="textarea" placeholder="News Content" /> */}
                </Form.Group>
              </Col>
            </Col>

            <Row className='mb-7'>
              <Col className='text-end'>
                <Form.Group>
                  <Button
                    type='submit'
                    className='btn btn-primary font-weight-bold me-3 fs-5 btn-block'
                    data-kt-users-modal-action='submit'
                    disabled={isLoading || btnLoading}
                  >
                    <span className='indicator-label'>{btnLoading ? "Please Wait..." : isEditing ? "Edit" : "Add"}</span>
                  </Button>

                  <Button
                    type='reset'
                    className='btn btn-light-primary fs-5 '
                    data-kt-users-modal-action='cancel'
                    onClick={()=>{
                      navigate('/news-articles/articles')
                    }}
                  >
                    Cancel
                  </Button>
                </Form.Group>
              </Col>
            </Row>
          </>
          }
        </Form>
        <ToastContainer className='p-3 position-fixed' position={'top-center'}>
          <Toast
            show={sessionMessage.show}
            bg={sessionMessage.type}
          >
            <Toast.Body className='text-white'>{sessionMessage.message}</Toast.Body>
          </Toast>
        </ToastContainer>
      </Col>
    </>
  )
}
export default AddEditNewsArticle
